/* eslint-disable */
import axios from 'axios'
import clickOutside from '../../directive'
import SNotification from "../../components/SNotification"
import Smodal from "../../components/Smodal"
import {Chart} from 'highcharts-vue'
//import Sparkline from 'vue-sparklines'
import mixin from './mixin'

export default {
	name: 'Project',
	data: function() {
		return  {
			msg: "Project",
			ll: null,
			qrlink:null,
			manager:null,
			arr:{
				idProject:null,
				page:null,
				site:null,
				se:null,
				dateFrom:null,
				dateTo:null,
				dateP: 0,
				dateAB:[null,null,null,null,null,null],
				offset:0,
				limit:100,
				fMain:null,
				fGroup:-1,
				fSort:null,
				fKw:"",
				fKwExact:true,
				fSeo:1,
				comparison:1,
                work:false,
				idb:0,
				subdomain:1
			},
			kwBlock:{
				show: false,
				exact: true
			},
			idu: null,
			arrcach:{
                comparison:null,
			},
            firstLoad: false,
			linkReport:{
				pdf:'',
				csv:''
			},
			load: false,
			stopLoad: false,
			bGroup:false,
			bSort:false,
			bKwSettings: false,
			oneKwChecked: false,
			bTop:false,
			position:[],
			posDates:[],
			posDatesSeo:[],
			posHeader: false,
			showYear:false,
			mainFilter: [null,500,null],
			sortFilter: [null,493,null],
			groupFilter: [-1,655,'All keywords'],
			triggerKw:null,
			api:[],
			countKw:0,
			groupList:[],
			showstat:true,
			checkAllKw: false,
			checkedKw: [],
			newGroup:{
				st:false,
				val:''
			},
			triggerStart: false,
			linkButtonFs: null, //for test not empty
			linkButtonType: 0,
			wip:false,
            smodal:{
				show: false,
				position: 0,
				date:null,
				kw:null,
				clear: false,
				row:null
			},
			popup5: false,
			typeEmptyPos: 0,
			notes:null,
			timerReport:null
		}
	},
	components: {
		SNotification,
        Smodal,
		highcharts: Chart,
		//Sparkline: Sparkline
	},
	computed:{
		lang(){
			return this.$root.lang;
		}
	},
	created() {
		/*document.addEventListener('click', (ev) => {

		});*/
		window.addEventListener('scroll', this.onScrollToBottom);
		window.addEventListener('message', this.listenerSmlt, false);
	},
	destroyed () {
		window.removeEventListener('scroll', this.onScrollToBottom);
		window.removeEventListener('message', this.listenerSmlt, false);
	},
	methods: {
		async init(){
			this.route();
			await this.getll();
			if(typeof $cookies.get('subdomainscheck')!=='undefined' && $cookies.get('subdomainscheck')==0) this.arr.subdomain = 0;
			//console.log(222, this.arr.subdomain);
			await this.main();
			this.setGroup();
		},
		async getll(){
			let res = await axios.post('/api/main/get', {datall:{"lang": this.lang,"controller": ["project","popup-rp","header-footer"]} });
			if(res && res.data && res.data.datall) {
				this.ll = res.data.datall;
				this.mainFilter[2] = this.ll['i'+this.mainFilter[1]];
				this.sortFilter[2] = this.ll['i'+this.sortFilter[1]];
				this.groupFilter[2] = this.ll['i'+this.groupFilter[1]];
			}
		},
		setDateProject(){
			let today = new Date();
			this.arr.dateTo = today.fdate();
			today.setDate(today.getDate()-6);
			this.arr.dateFrom = today.fdate();
		},
		async main(){
			/*let res = await axios.post('/api/main/get', {controller:[{name:"wechat"}, {name:"manager"}] });
			if(res && res.data ) {}*/
			//console.log(this);

			if(!this.$root.prod){
                this.getProject();
            }
			let res = await axios.post('/api/project/get', {controller:[
				{name:"group", data:null},
				{name:"pdflink", data:this.arr},
				{name:'chw', data:null},
				{name:'popup5', data:null},
				{name:'notes', data:null}
			], idProject:this.arr.idProject});
			if(res && res.data){
				if(res.data.group && res.data.group.res && res.data.group.count ){
					this.groupList = res.data.group.res.splice(1);
					this.countKw = res.data.group.count;
				}
				if(res.data.pdflink ){

				}
				if(res.data.chw && res.data.chw.result) this.arr.work=true;
				if(res.data.popup5 && res.data.popup5.result){
					this.popup5 = true;
				}
				if(res.data.notes && res.data.notes.res) this.notes = res.data.notes.res;
			}
		},
		async getProject(reset){
            if(!this.firstLoad && $cookies && $cookies.get('free9keyword')){
                this.firstLoad = true;
                this.filter_groups(0, 644);
                return;
            }
			this.triggerStart = true;
			reset = reset || false;
			if(reset){
				this.load = false;
				this.stopLoad = false;
				this.arr.offset = 0;
				this.position = [];
				this.posDates = [];
				this.posHeader = false;
				this.showYear = false;
			}

			if(this.load || this.stopLoad) return;
			this.load = true;

			this.arr.fKwExact = (this.arr.fGroup>-1) ? this.kwBlock.exact : true;
			//this.arr.fKwExact = (this.arr.fGroup>-1) ? false : true;
			let res = await axios.post('/api/project/get', {controller:[
					{name:"project", data:this.arr}
				], idProject:this.arr.idProject}).catch((ee)=>{
					console.log('#error',  ee);
					//this.$refs.snotify.text(ee,5000);
			});
			if(res && res.data){
				if(res.data.project && res.data.project.res){
					//this.showYear = !res.data.project.year;
					this.position = this.position.concat(res.data.project.res);

					if(res.data.project.dates) this.posDates = res.data.project.dates;
					if(res.data.project.datesSeo){
						this.posDatesSeo = res.data.project.datesSeo;
						//this.showYear = false;
					}

					this.arr.offset += this.arr.limit;
					if(this.arr.offset>0 && res.data.project.res.length>0) this.posHeader = true;

					this.arr.dateAB = res.data.project.dateAB;
					if(res.data.project.url ) this.api.push({'speed':res.data.project.speed, 'link':res.data.project.url});
					if(res.data.project.res.length==0) this.stopLoad = true;
					if(res.data.project.wip) this.wip = true;

					if(typeof langDate=='function'){
                    	this.$nextTick(function () {
                            langDate();
                        });
					}
                    /* For SEO 2020-06-10
                    if(!this.arr.fMain && this.arr.fGroup==-1 && !this.arr.fSort && !this.arr.fKw
						&& this.arr.comparison==1 && this.arr.offset==this.arr.limit && res.data.project.total < 10
						&& res.data.project.seo && res.data.project.seo.after>0 && res.data.project.seo.after<7) {

						this.arrcach.comparison = this.arr.comparison;
                        this.arr.comparison = 3;
                        this.arr.offset = 0;
                        this.load = false;
                        this.getProject();
                        return;
                    }*/
                    if( !this.firstLoad
                        && !this.arr.fMain && this.arr.fGroup==-1 && !this.arr.fSort && !this.arr.fKw
                        && this.arr.offset==this.arr.limit && res.data.project.total < 10
                        && res.data.project.user && res.data.project.user['license_type']==0 ) {

                        this.firstLoad = true;
                        this.load = false;

                        this.loadDefaultMyKeywords();
                    }
                    this.firstLoad = true;
				}
                if(res.data.project && res.data.project.user&& res.data.project.user.id){
					this.idu = res.data.project.user.id;
				}
			}
			this.load = false;
			this.linkReportGet();
		},
        async loadDefaultMyKeywords(){
            let res2 = await axios.post('/api/project/get', {controller:[{
                    name:"addkwgroup",
                    data:{
                        all:true,
                        keywords:null,
                        groupFrom: -1,
                        groupTo:0,
                        idProject:this.arr.idProject,
                        date:this.posDates[this.posDates.length-1]
                    }
                }], idProject:this.arr.idProject});
            if(res2 && res2.data && res2.data.addkwgroup && res2.data.addkwgroup.res){
                this.updateGroup();
                this.filter_groups(0, 644);
                let in5minutes = new Date(new Date().getTime() + 5 * 60 * 1000);
                $cookies.set('free9keyword', 1, in5minutes, '/project/'+this.arr.idProject, '.semalt.com');
            }
        },
		route(){
			if(this.$route.params.lang){
				if(this.$root.languages.includes(this.$route.params.lang)){
					if(this.$route.params.lang != this.$root.lang) {
						this.$root.lang = this.$route.params.lang;
					}
				}
				else{
					this.$router.push('/en/project');
					this.$router.push('/project');
					this.$root.lang = 'en';
				}
			}
			else if(this.$route.query.lang){
				if(this.$root.languages.includes(this.$route.query.lang)){
					this.$root.lang = this.$route.query.lang;
				}
			}
			this.arr.idProject = parseInt(this.$route.params.idproject);
			if(this.$route.query){
				this.arr.comparison = 0;
				if(this.$route.query.c) this.arr.comparison = this.$route.query.c;
				if(this.$route.query.from) this.arr.dateFrom = this.$route.query.from;
				if(this.$route.query.to) this.arr.dateTo = this.$route.query.to;

				if(this.$route.query.kw) this.arr.fKw = decodeURIComponent(this.$route.query.kw);
				if(this.$route.query.idg && parseInt(this.$route.query.idg)>0 || typeof this.$route.query.idg !=='undefined' && this.$route.query.idg==0 ) {
					this.arr.fGroup = parseInt(this.$route.query.idg);
					if(this.arr.fGroup==0){
						this.groupFilter = [0,644,'My keywords'];
					}
				}
			}
		},
		setGroup(){
			if(this.arr.fGroup>0){
				let jj =  this.groupList.findIndex(row => row.id == this.arr.fGroup);
				if(jj>-1){
					this.groupFilter = [this.arr.fGroup,null,this.groupList[jj].name];
				}
			}
		},
		filterPos(value, llid){
			if(this.load) return;
			this.arr.fMain = value;
			this.mainFilter[0] = value;
			this.mainFilter[1] = llid;
			this.mainFilter[2] = this.ll['i'+llid];
			if(this.arr.fMain!==null) this.typeEmptyPos = 1;
			else {
                if(this.arr.fKw.length>2) this.typeEmptyPos = 2;
				else this.typeEmptyPos = 0;
			}
			this.getProject(true);
		},
		sortPos(value, llid){
			if(this.load) return;
			this.arr.fSort = value;
			this.sortFilter[0] = value;
			this.sortFilter[1] = llid;
			this.sortFilter[2] = this.ll['i'+llid];
			this.bSort = false;
			this.getProject(true);
		},
		changeKwFilter(ee){
		    if(this.arr.fGroup>-1){
                if (ee.keyCode === 13){
                    this.getProject(true);
                    this.kwBlock.show = false;
                }
            }
            else{
                clearTimeout(this.triggerKw);
                this.triggerKw = setTimeout(()=>{
                    if( this.arr.fGroup==-1 && (this.arr.fKw.length>2 || this.arr.fKw.length==0) ){
                        /*if(this.arr.fGroup==-1){ //заглушка, потому что АПИ не пашет
                            this.arr.fGroup = 0;
                            this.groupFilter[0] = 0;
                            this.groupFilter[1] = 644;
                            this.groupFilter[2] = this.ll['i644'];
                        }*/
                        if(this.arr.fKw.length>2) this.typeEmptyPos = 2;
                        else {
                            if(this.arr.fMain!==null) this.typeEmptyPos = 1;
                            else this.typeEmptyPos = 0;
                        }
                        this.getProject(true);
                    }
                },800);
            }
		},
		showBlock(value){
			if(value=='bKwSettings'){
				if(this[value]){
					this[value] = !this[value];
				}
				else{
					if(this.checkedKw.length>0) this[value] = !this[value];
					else{
						this.$refs.snotify.text(this.ll.i3488 || 'Please select one or more keywords!',1000);
					}
				}
			}
			else{
				this[value] = !this[value];
			}
		},
		outsideBlockTop(){
			this.bTop = false;
		},
		outsideBlockKwSettings(){
			if(this.newGroup.st!=false) return;
			this.bKwSettings = false;
		},
		outsideBlockSort(){
			this.bSort = false;
		},
		outsideBlockGroup(){
			this.bGroup = false;
		},
		kwBlockOutside(){
			this.kwBlock.show = false;
		},
		kwBlockFunc(){

		},
		applyKwFilter(){
			if(this.arr.fKw.length==0) return;
			this.getProject(true);
            this.kwBlock.show = false;
		},
		resetKwFilter(){
			this.arr.fKw='';
			this.getProject(true);
            this.kwBlock.show = false;
		},
		pDate(date){
			let arr = new Date(date);
			arr = arr.toString().split(' ');
			let res = arr[1]+' '+arr[2];
			if(this.showYear) res += ' '+arr[3];
			return res;
		},
		llDate(date){
			let arr = new Date(date);
			arr = arr.toString().split(' ');
			let res = arr[1]+' '+arr[2]+' '+arr[3];
			return res;
		},
		keyDate(date){
			return "d" + date.replace(/-/g,'');
		},
		keyClass(val){
			if(val==1) return 'plusProject';
			else if(val==-1) return 'minusProject';
            else if(val==-2) return 'minusProject popupPos5';
			return 'grayProject';
		},
		lltitle(val){
			if(val.indexOf('||')>0) {
				let arr = val.split('||');
				if(arr[0] && this.ll['i'+arr[0]]){
					if(arr[2]) return this.ll['i'+arr[0]].replace('%s', arr[2]);
                    return this.ll['i'+arr[0]];
				}
				return '';
			}
			if(this.ll['i'+val]) return this.ll['i'+val];
			return '';
		},
		onScrollToBottom(){
			if(!this.triggerStart) return;
			let OFFSET = 400;
			let scrollBottom = this.scrollTop() + window.innerHeight;
			let docHeight = document.body.scrollHeight;
			if (scrollBottom > docHeight - OFFSET) {
				this.getProject();
			}
		},
		scrollTop() {
			return window.scrollY != null ? window.scrollY : window.pageYOffset;
		},
		nextDate(val){
			if(this.load) return;
			if(this.arr.dateP==0&&val==-1) return;
			this.arr.dateP += val;
			this.getProject(true);
		},
		listenerSmlt(event){
			if(event.data){
				//console.log(event.data);
				/*try {
					let param = JSON.parse(event.data);
					if(param.action){
						console.log(param);
						if(param.action=='scroll'){
							this.getProject();
						}
						else if(param.action=='changeDates'){
							this.arr.dateFrom = param.data.dates[0];
							this.arr.dateTo = param.data.dates[1];
							this.arr.comparison = param.data.comparison;

							this.getProject();
						}
					}
				}
				catch(ee){console.log(ee);}
				*/
			}
		},
		postMessage(action, data){
			let req = {"action":action, "data":data};
			window.top.postMessage(JSON.stringify(req), '*' );
		},
		clickButton(action, data){

		},
		filter_groups(value, llid, groupname){
			if(this.load) return;
			if(this.arr.comparison==3) this.arr.comparison = this.arrcach.comparison;
			groupname = groupname || '';
			this.arr.fGroup = value;
			this.groupFilter[0] = value;
			if(llid){
				this.groupFilter[1] = llid;
				this.groupFilter[2] = this.ll['i'+llid];
			}
			else{
				this.groupFilter[1] = null;
				this.groupFilter[2] = groupname;
			}
			this.checkedKw = [];
			this.checkAllKw = false;
			this.getProject(true);
		},
		checkAllKwF(event){
			//console.log(this.checkAllKw, event);
			if(event){
				if(this.checkAllKw){
					this.checkedKw = [];
					for(let jj in this.position){
						this.checkedKw.push([this.position[jj].id,this.position[jj].kw]);
					}
				}
				else{
					this.checkedKw = [];
				}
			}
			//console.log('!!!', this.checkedKw.length);
		},
		async addToGroup(id){
			if(this.arr.fGroup==id || this.arr.fGroup>0 && id<=0 ) return;

			let res = await axios.post('/api/project/get', {controller:[{
					name:"addkwgroup",
					data:{
						all:this.checkAllKw,
						keywords:this.checkAllKw?null:this.checkedKw,
						groupFrom: this.arr.fGroup,
						groupTo:id,
						idProject:this.arr.idProject,
						date:this.posDates[this.posDates.length-1]
					}
				}], idProject:this.arr.idProject});
			if(res && res.data && res.data.addkwgroup && res.data.addkwgroup.res){
				this.updateGroup();
				this.checkedKw = [];
			}
		},
        async removeFromGroup(){
            let res = await axios.post('/api/project/get', {controller:[{
                    name:"removefromgroup",
                    data:{
                        all:this.checkAllKw,
                        keywords:this.checkAllKw?null:this.checkedKw,
                        groupFrom: this.arr.fGroup,
                        idProject:this.arr.idProject,
                        date:this.posDates[this.posDates.length-1]
                    }
                }], idProject:this.arr.idProject});
            if(res && res.data && res.data.removefromgroup && res.data.removefromgroup.res){
                this.updateGroup();
                this.checkedKw = [];
                this.getProject(true);
            }
        },
		async updateGroup(){
			let res = await axios.post('/api/project/get', {controller:[
					{name:"group", data:null}
				], idProject:this.arr.idProject});
			if(res && res.data){
				if(res.data.group && res.data.group.res && res.data.group.count ){
					this.groupList = res.data.group.res.splice(1);
					this.countKw = res.data.group.count;
				}
			}
		},
		async deleteMyKw(){
			let res = await axios.post('/api/project/get', {controller:[{
					name:"deleteMyKw",
					data:{
						all:this.checkAllKw,
						keywords:this.checkAllKw?null:this.checkedKw,
						group: this.arr.fGroup,
						idProject:this.arr.idProject,
                        arr:this.arr
					}
				}], idProject:this.arr.idProject});
			if(res && res.data && res.data.deleteMyKw && res.data.deleteMyKw.res ){
				this.getProject(true);
			}
		},
		linkReportGet(){
			let data = {
				type:'pdf',
				three: 1,
				c:this.arr.comparison==0?0:1,
			};
			let link = 'https://semalt.com/ajax/report/report_project.php?project='+this.arr.idProject+
				'&fromD='+ this.arr.dateFrom+'&toD='+ this.arr.dateTo+'&lang='+this.$root.lang+'&idb='+ this.arr.idb+
				'&format='+data.type+'&three='+data.three+'&c='+data.c+'&id_group='+this.arr.fGroup+
				'&filter='+this.arr.fMain+'&sort='+this.arr.fSort+'&sub='+this.arr.subdomain;
            if(this.arr.fKw.length>2) link +='&search='+this.arr.fKw;

			this.linkReport.pdf = link;
			this.linkReport.csv = link.replace('&format=pdf','&format=csv');
			if(this.wip && this.$root.lang!='en'){
				this.linkReport.enpdf = this.linkReport.pdf.replace('&lang='+this.$root.lang,'&lang=en');
				this.linkReport.encsv = this.linkReport.csv.replace('&lang='+this.$root.lang,'&lang=en');
			}
			clearTimeout(this.timerReport);
		},
		async getExternalLink(row){
			let res = await axios.post('/api/project/get', {controller:[{
					name:"googleLink",
					data:{
						idProject: this.arr.idProject,
						idkw:row.id,
						kw: row.kw
					}
				}], idProject:this.arr.idProject});
			if(res.data && res.data.googleLink && res.data.googleLink.res){
				this.$set(row, 'google', res.data.googleLink.res);
				//window.open(data);
			}
		},
		async infoKw(row){
			if(row.sKwB) return;
            if(row.sKw){
				row.sKw = false;
				return;
			}
			if(!row.sKwComp){
				let res = await axios.post('/api/project/get', {controller:[{
						name:"kwCompetitor",
						data:{
							idProject: this.arr.idProject,
							idkw:row.id,
							kw: row.kw,
							today: this.arr.dateTo
						}
					},{
						name:"kwGraphics",
						data:{
							idProject: this.arr.idProject,
							arr:row.data,
						}
					}], idProject:this.arr.idProject});
				if(res.data && res.data.kwCompetitor && res.data.kwCompetitor.res){
					row.sKwComp = res.data.kwCompetitor.res;
					if(res.data.kwCompetitor.site) this.arr.site = res.data.kwCompetitor.site;
					if(res.data.kwCompetitor.date) this.$set(row, 'listDate', res.data.kwCompetitor.date );
					this.$nextTick(()=>{

					});
				}
				if(res.data && res.data.kwGraphics && res.data.kwGraphics.res){
					this.$set(row, 'sKwGData', res.data.kwGraphics.res );
					this.$set(row, 'sKwGMin', res.data.kwGraphics.min );
					this.$set(row, 'sKwGMax', res.data.kwGraphics.max );
				}
			}
			row.sKw = true;
			let gData = this.getGraphics(row.kw, row.sKwGMin, row.sKwGMax, row.sKwGData);
			//this.$set(row, 'sKwG', gData );
			row.sKwG = gData;

		},
		getGraphics(keywordName, smin, smax, data){
			//console.log([keywordName, smin, smax, data]);
			let self = this;
			let dataG = {
				chart: {
					type: 'line',
					style: {"fontWeight": "normal", "marginTop": "1px"}
				},
				title: {
					text: keywordName,
					style: { "color": "#333333", "fontSize": "16px"  }
				},
				subtitle: {
					text: ''
				},
				xAxis: {
					type: 'datetime',
					minTickInterval: 86400000,
					labels: {
						format: '{value:%b %e}',
					}
				},
				yAxis: {
					allowDecimals: false,
					title: {
						text: 'Positions'
					},
					reversed: true,
					min: smin,
					max: smax,
					//tickInterval: 1,
					//tickPositions: pp
				},
				tooltip: {
					crosshairs: true, //вертикальная линия при наведении
					enabled: true,
					formatter: function() {
						if(this.y == 1){
							return '<b>' + self.df(this.x,2)+ '</b><br>'+ this.series.name +': <b>'+ this.y + '</b> ' + (self.ll.i3983 ||'<sup> th</sup> position');
						}
						else{
							return '<b>' + self.df(this.x,2)+ '</b><br>'+ this.series.name +': <b>'+ this.y + '</b> ' + (self.ll.i3984 ||'<sup> th</sup> positions');
						}
					},
					useHTML: true
				},
				exporting: {
					enabled: false
				},
				credits: {
					enabled: false
				},
				scrollbar : {
					enabled : false
				},
				navigator: {
					enabled: false
				},
				rangeSelector: {
					enabled: false,
					selected: true
				},
				plotOptions: {
					line: {
						dataLabels: {
							enabled: true,
							style: {
								textShadow: '0 0 3px white, 0 0 3px white',
								fontWeight: 'normal'
							}
						},
						enableMouseTracking: true
					}
				},
				series: [{
					name: keywordName,
					showInLegend: false,
					data: data
				}]
			}
			return dataG;
		},
		clickNewGroup(){
			this.newGroup.st = true;
		},
		async newGroupSave(){
			let res = await axios.post('/api/project/get', {controller:[{
					name:"addnewgroup",
					data:{
						idProject: this.arr.idProject,
						name:this.newGroup.val
					}
				}], idProject:this.arr.idProject});
			if(res.data && res.data.addnewgroup && res.data.addnewgroup.res && res.data.addnewgroup.id){
				await this.addToGroup(res.data.addnewgroup.id);
				this.updateGroup();
				this.newGroup.val = '';
				this.newGroup.st = false;
			}
		},
		mouseEnter(row){
			if(!this.linkButtonFs) return;
			row.sfs = true;
		},
		mouseLeave(row){
			if(!this.linkButtonFs) return;
			row.sfs = false;
		},
        changePos(row, date){
			if(!this.arr.work) return;
            this.smodal.date = date;
            this.smodal.kw = row.kw;
            this.smodal.position = row.data[this.keyDate(date)].pos;
            this.smodal.show = true;
            this.smodal.row = row;
		},
        async savePos(val){
            this.smodal.show = false;
            let data = {
                idProject: this.arr.idProject,
				date:this.smodal.date,
                kw:this.smodal.kw,
				position:this.smodal.position,
                clear:this.smodal.clear
			}
            let res = await axios.post('/api/project/changepos', data);
            if(res && res.data && res.data.result){
            	if(this.smodal.clear)this.smodal.row.data[this.keyDate(this.smodal.date)].pos= '?';
                else this.smodal.row.data[this.keyDate(this.smodal.date)].pos= this.smodal.position;
			}
		},
		loadReport(type){
			let value = this.linkReport[type];
			if(value){
				//let iframe = document.getElementById('export_iframe');
				setTimeout(()=>{
					this.linkReport[type] = null;
					/*iframe.onload = ()=> {
                        //this.linkReport[type] = value;
                        console.log('PDF download');
                    };*/
				},100);
				this.timerReport = setTimeout(()=>{
					if(this.linkReport[type] == ''||this.linkReport[type] ==null ) this.linkReport[type] = value;
				},6000);
			}
		}

	},
	mixins: [mixin],
	mounted(){
		this.init();
	},
	watch:{
		lang(to, from){
			this.getll();
		},
		checkedKw(to, from){
			if(to.length < from.length){
				this.checkAllKw = false;
			}
			if(to.length>0){
				this.bKwSettings = true;
			}
		}
	},
}