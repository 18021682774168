<template>
	<div v-if="mainValue" class="s-notification">
		<div class="s-notification-center">{{mainValue}}</div>
	</div>
</template>
<script>
	/* eslint-disable */
	export default {
		name: 'SNotification',
		components: {

		},
		computed: {

		},
		data: function() {
			return {
				mainValue: false,
				delayDefault: 3000,
				delay:null
			}
		},
		methods: {
			init(){
				this.delay = this.delayDefault;
			},
			text(text, delay){
				if(!text) return;
				this.mainValue = text;
				if(delay && delay>200) this.delay = delay;
				setTimeout(()=>{ this.mainValue=false; this.delay=this.delayDefault; }, this.delay);
			}
		},
		mounted(){
			this.init();
		},
	}
</script>
<style lang="css">
.s-notification{
	position: fixed;
	display: block;
	width: 100%;
	top: 42%;
	z-index: 9999;
	opacity: 1;
	-webkit-transition: all 0.5s;
	transition: all 0.5s;
	margin: 0;
	padding: 0;
	left: 0;
  text-align: center;
}
.s-notification	.s-notification-center{
	max-width: 780px;
	display: inline-block;
	color: #fff;
  font-family: "Ebrima", "MyriadPro Light";
	font-size: 22px;
	font-weight: bold;
	background: #000;
	border-radius: 20px;
	padding: 16px 30px;
	opacity: 0.85;
}
</style>