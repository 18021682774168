<template>
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">

                    <div class="modal-header">
                        <slot name="header">
                            Default header
                        </slot>
                    </div>

                    <div class="modal-body">
                        <slot name="body">
                            Default body
                        </slot>
                    </div>

                    <div class="modal-footer">
                        <slot name="footer">
                            <button class="button-cancel" @click="$emit('close')">
                                Cancel
                            </button>
                            <button class="button-ok" @click="$emit('save', value)">
                                OK
                            </button>
                        </slot>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
/* eslint-disable */
export default {
    name: 'Smodal',
    props: ['value']
}
</script>
<style lang="css" scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: table;
    transition: opacity 0.3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-container {
    width: 300px;
    margin: 0px auto;
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
    transition: all 0.3s ease;
    font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
    margin-top: 0;
    color: #42b983;
}

.modal-body {
    margin: 20px 0;
}

.modal-default-button {
    float: right;
}

.modal-enter {
    opacity: 0;
}

.modal-leave-active {
    opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
}
.clear{
    clear: both;
}
.button-ok{
    min-width: 90px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 4px;
    border: none;
    background-color: #2ecc71;
    color: #fff;
    font-weight: bold;
    display: inline-block;
    font-size: 14px;
    outline: none;
    cursor: pointer;
    margin-left: 20px;
}
.button-ok:hover{
    opacity: 0.5;
}
.button-cancel{
    min-width: 90px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    border-radius: 4px;
    border: none;
    background-color: #eee;
    color: #9a9a9b;
    font-weight: bold;
    display: inline-block;
    font-size: 14px;
    outline: none;
    cursor: pointer;

}
.button-cancel:hover{
    opacity: 0.5;
}
input[type="text"]{
    font-size: 14px;
    border: 1px solid #aaaaaa;
    padding: 7px 7px 7px 10px;
    border-radius: 3px;
    width: 100%;
    color: #818181;
    outline: none;
}
table tr td{
    text-align: left;
    font-size: 13px;
}
</style>